/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Menu from "./menu"
import layoutStyles from "./layout.module.css"

const Layout = ({ children, title }) => {
  return (
    <div className={layoutStyles.layout}>   
      <Header pageTitle={title} />
      <Menu />
      <main className={layoutStyles.main}>
        {children}
      </main>
      <footer className={layoutStyles.footer}>
        <div className={layoutStyles.footerimg}></div>
          <div>© {new Date().getFullYear()}, Siteware. All rights reserved.</div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout