import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const GamesPage = () => (
  <Layout title="Games">
      <h3>Worm Hunt</h3> 
          <div>
          <img src="../images/wormhunt-logo-3.png" alt="wormhunt logo" className="title-image"/>
          <p>As a mole your goal is to eat as many worms as possible while avoiding being blown up by the mines. 
              Several power-ups give you special abilities. 
              You can play alone or challenge a friend in multiplayer mode to see who can cross the finish line with the most worms in their belly.</p>
          </div>
          <div style={{clear: `both`}}>
          <img style={{ maxWidth:`100%`, width: `auto`, height: `auto`, margin:`5px 0`}} src="../images/singleplayer.png" alt="single player"/>
          </div>
          <div style={{display: `flex`, flexWrap: `wrap`, alignItems: `center`, justifyContent: `center`}}>
            <img style={{margin: `5px`, height: `40px`}} src="../images/apple-app-store-badge.svg"/>
            <img style={{margin: `5px`, height: `40px`}} src="../images/google-play-badge.svg"/>
          </div>
          <div style={{display:`flex`, justifyContent: `center`}}>
           <Link style={{margin: `10px`}} to="/wormhunt/privacy">Privacy Policy</Link>
           <Link style={{margin: `10px`}} to="/wormhunt/terms-and-conditions">Terms &amp; Conditions</Link>
           </div>
  </Layout>  
)

export default GamesPage
