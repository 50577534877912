import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerStyles from "./header.module.css"

const Header = ({ pageTitle }) => (
  <header className={headerStyles.header}>
      <Link to="/"><img src="../images/siteware-logo.png" alt="siteware logo"/></Link>
      <h1 className={headerStyles.title}>
          {pageTitle}
      </h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
