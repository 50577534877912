import { Link } from "gatsby"
import React from "react"
import menuStyles from "./menu.module.css"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : {}
}

const Menu = (props) => {

    return (
      <nav className={menuStyles.navigation}>
        <ul className={menuStyles.list}>
          <li key="/"><Link to="/" getProps={isActive} {...props}>HOME</Link></li>
          <li key="/service"><Link to="/services" getProps={isActive} {...props}>SERVICES</Link></li>
          <li key="/games"><Link to="/games" getProps={isActive} {...props}>GAMES</Link></li>
          <li key="/contact"><Link to="/contact" getProps={isActive} {...props}>CONTACT</Link></li>
        </ul>    
      </nav>
    )
}

export default Menu
